import React, { Component } from "react";

import styles from "./ForgotPassword.module.scss";
import success from "../../assets/icons/success.svg";

class ForgotPasswordSuccess extends Component {
  constructor(props) {
    super(props);
    this.metaData = {
      title:
        "We have sent you a link on your registered email to reset your password. Please check your mail.",
      btnTitle: "Go to Login",
      footer: "Still having trouble in Login? ",
      contactUs: "Contact Support",
    };
  }
  handleRedirect = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("defaultURI")) {
      window.location = params.get("defaultURI");
    } else {
      window.location = process.env.REACT_APP_IDENTITY_URL;
    }
  };

  render() {
    return (
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.successTick}>
            <img src={success} alt="success" height="44px" />
          </div>
          <div className={styles.successHead}>{this.metaData.title}</div>
          <div className={styles.loginContainer}>
            <div
              className={styles.loginRedirectAlign}
              onClick={() => this.handleRedirect()}
            >{this.metaData.btnTitle}</div>
          </div>

          <div className={styles.footer}>
            {this.metaData.footer}
            <span
              className={styles.contactUs}
              onClick={this.props.onContactClick}
            >
              {this.metaData.contactUs}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordSuccess;
