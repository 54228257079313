import React from "react";
import OtpInput from "react-otp-input";
import cx from "classnames";

import styles from "./OtpScreen.module.scss";
import BPlogo from "../../../assets/icons/betterPlaceNewLogo.svg";
import refreshIcon from "../../../assets/icons/Resend.svg";
import editIcon from "../../../assets/icons/Edit.svg";
import dots from "../../../assets/icons/dots.svg";
import warning from "../../../assets/icons/warning.svg";

const authenticatorTypeNames = {
  OTP_EMAIL: "mail",
  OTP_NUMBER: "mobile number",
};

const OtpScreen = ({
  authenticatorType = "",
  screen = "",
  otp = "",
  seconds = 0,
  userName = "",
  getOtp = () => {},
  handleChange = () => {},
  handleEditUserName = () => {},
  error = "",
}) => {
  return (
    <div className={styles.alignMiddle}>
      <div className={styles.alignDots}>
        <img src={dots} alt="betterplace" height="66px" />
      </div>
      <div
        style={{ marginBottom: "2px", marginTop: "50px", marginLeft: "20px" }}
      >
        <img src={BPlogo} alt="bplogo" height="40px" />
      </div>
      <div className={styles.loginHeadBold}> betterplace</div>

      <div>
        <div className={styles.loginHeadingOtp}>Enter OTP</div>

        <div className={styles.loginHeadingSmall}>
          {screen === "MFA_OTP"
            ? `Enter the 6-digit verification code send to your registered ${
                authenticatorType === "OTP_EMAIL"
                  ? authenticatorTypeNames.OTP_EMAIL
                  : authenticatorTypeNames.OTP_NUMBER
              }`
            : `Betterplace has sent you a OTP to`}
        </div>

        <div>
          {screen !== "MFA_OTP" ? (
            <div className={styles.textPrimary}>
              {userName}
              <img
                src={editIcon}
                alt="edit"
                height="16px"
                onClick={handleEditUserName}
                className={cx(styles.pointer, "ml-2")}
              />
            </div>
          ) : null}

         
          <OtpInput
            value={otp}
            containerStyle={styles.outerOtpContainer}
            onChange={handleChange}
            numInputs={6}
            inputStyle={styles.otpContainer}
            isInputNum
            focusStyle={{ outline: "none" }}
          />
          <div className={styles.resendContainer}>
            <div
              className={
                seconds > 0 ? styles.resendTextDisabled : styles.resendText
              }
              onClick={getOtp}
            >
              <img
                src={refreshIcon}
                alt="refesh"
                className="mr-1"
                height="16px"
              />
              Resend OTP
            </div>           
            <div
              className={cx(
                styles.resendSeconds,
                seconds > 0 ? styles.resendText : styles.resendTextDisabled
              )}
            >
              {seconds} seconds left
            </div>       
          </div>
        </div>
      </div>

      {error && (
        <div className={styles.warning}>
          <img src={warning} alt="warning" className="mr-1" />
          {error}
        </div>
      )}
    </div>
  );
};

export default OtpScreen;
