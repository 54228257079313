import React from "react";
import cx from "classnames";

import styles from "./ContactUsModal.module.scss";
import Modal from "../../atoms/Modal/Modal";
import dots from "../../../assets/icons/dots.svg";
import ContactUs from "../../../assets/icons/ContactUs.svg";

const ContactUsMetaData = {
  heading: "Are you facing trouble during login?",
  suHeading: " Contact Support",
  footer: "Go back",
  mailTo: "mailto:support@betterplace.co.in",
};

const ContactUsModal = ({ handleClose, show }) => {
  return (
    <Modal
      modalClassName={styles.container}
      className={styles.backContainer}
      handleClose={handleClose}
      show={show}
    >
      <div className={cx(styles.scrollbar, styles.pageContainer)}>
        <div className={styles.alignMiddle}>
          <div className={styles.alignDots}>
            <img src={dots} alt="betterplace" height="80px" />
          </div>
          <div className={styles.contactUsIcon}>
            <img src={ContactUs} alt="ContactUs" />
          </div>
          <div className={styles.content}>
            <div className={styles.heading}>
              {ContactUsMetaData.heading}
              <br/>
              <span
                className={styles.link}
                onClick={(e) => {
                  window.open(process.env.REACT_APP_SUPPORT_URL, "_blank");
                  e.preventDefault();
                }}
              >
                {ContactUsMetaData.suHeading}
              </span>
            </div>
            <span className={styles.backBtn} onClick={handleClose}>
              {ContactUsMetaData.footer}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContactUsModal;
