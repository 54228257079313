import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as actions from "./Store/action";
import isEmpty from "lodash/isEmpty";
import styles from "./LoginPage.module.scss";
import cx from "classnames";
import BPlogo from "../../assets/icons/betterPlaceNewLogo.svg";
import speakerIcon from "../../assets/icons/Speaker.svg";
import editIcon from "../../assets/icons/Edit.svg";
import arrowRight from "../../assets/icons/arrow-right.svg";
import dots from "../../assets/icons/dots.svg";
import warning from "../../assets/icons/warning.svg";
import PasswordInput from "../../components/atoms/PasswordInput/PasswordInput";
import TfaSelection from "../../components/organisms/TfaSelection/TfaSelection";
import ContactUsModal from "../../components/molecules/ContactUsModal/ContactUsModal";

import {
  fetchMFAStatus,
  authenticate,
  challengeMFA,
  verifyMFAOtp,
} from "../../helpers";
import OtpScreen from "../../components/molecules/OtpScreen/OtpScreen";
import { EMAIL_REGEX, MOBILE_REGEX, USERNAME_REGEX } from "../../utils";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: {
        userName: "",
        password: "",
      },
      enableNext: false,
      showError: false,
      showPassword: false,
      useCode: false,
      OTP: "",
      seconds: 0,
      showMFA: false,
      isMFAChecked: false,
      mfaEnabled: false,
      authenticator: [],
      authenticatorType: null,
      mfaToken: null,
      showContactSupport: false,
      currentScreen: "LOGIN",
      errMsg: "",
      loading: false,
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;
    console.log(history.location);
    const newState = history.location.state;
    if (newState?.currentScreen === "MFA_SELECTION" && newState?.mfaToken) {
      this.setState({
        ...this.state,
        ...newState,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.login !== this.state.login) {
      if (
        this.state.login.userName.length !== 0 &&
        this.state.login.password.length !== 0
      ) {
        this.setState({
          enableNext: true,
        });
      } else {
        this.setState({
          enableNext: false,
        });
      }
    }
    if (prevProps.postLoginDataState !== this.props.postLoginDataState) {
      if (this.props.postLoginDataState === "ERROR") {
        this.setState({
          showError: true,
        });
      }
      if (this.props.postLoginDataState === "SUCCESS") {
        const redirectURI = this.props.loginData["redirectURI"];
        window.location.href = redirectURI;
      }
    }

    if (prevProps.postRequestOTPState !== this.props.postRequestOTPState) {
      if (this.props.postRequestOTPState === "SUCCESS") {
        this.setState({
          useCode: true,
          seconds: this.props.waitingTime,
        });
        this.startTimer();
      }
    }
  }

  handleInputChange = (event, inputIdentifier) => {
    this.setState((state) => ({ ...state, showError: false, errMsg: "" }));
    let updatedFormData = {
      ...this.state.login,
    };
    updatedFormData[inputIdentifier] = event.target
      ? event.target.value
      : event.value;
    this.setState({
      login: updatedFormData,
    });
  };

  handleNext = (event) => {
    event.preventDefault();
    if (
      this.state.useCode &&
      this.state.isMFAChecked &&
      !this.state.mfaEnabled
    ) {
      let payload = {
        userName: this.state.login.userName,
        otp: this.state.OTP,
      };
      this.props.onPostLoginData(payload);
    } else if (!this.state.isMFAChecked) {
      //api calling to check if mfa enabled
      let userType = "";
      if (MOBILE_REGEX.test(this.state.login.userName)) {
        userType = "MOBILE";
      } else if (EMAIL_REGEX.test(this.state.login.userName)) {
        userType = "EMAIL";
      } else {
        userType = "USERNAME";
      }
      this.setState((state) => ({ ...state, loading: true }));
      fetchMFAStatus({ userType, userName: this.state.login.userName })
        .then(({ mfa }) => {
          this.setState({
            ...this.state,
            isMFAChecked: true,
            mfaEnabled: mfa,
            loading: false,
          });
        })
        .catch((err) =>
          this.setState((state) => ({
            ...state,
            showError: true,
            errMsg: err,
            loading: false,
          }))
        );
    } else {
      if (this.state.mfaEnabled) {
        this.setState((state) => ({ ...state, loading: true }));
        authenticate({
          username: this.state.login.userName,
          password: this.state.login.password,
        })
          .then((data) => {
            if (data["redirectURI"]) {
              const redirectURI = data["redirectURI"];

              window.location.href = redirectURI;
              this.setState({
                ...this.state,
                loading: false,
              });
            } else {
              this.setState({
                ...this.state,
                showMFA: true,
                currentScreen: "MFA_SELECTION",
                authenticator: data.authenticators,
                mfaToken: data.mfaToken,
                loading: false,
              });
            }
          })
          .catch((err) =>
            this.setState((state) => ({
              ...state,
              showError: true,
              errMsg: err,
              loading: false,
            }))
          );
        // api for mfa authentication gives sms and mail id details
      } else {
        let data = { ...this.state.login };
        this.props.onPostLoginData(data);
      }
    }
  };

  handleForgotPasswordUrl = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("defaultURI")) {
      return `/forgot-password?${params.toString()}`;
    }
    return `/forgot-password`;
  };

  checkValidUserName = (isUserName = false) => {
    let user = this.state.login.userName;
    let isValid = false;
    if (EMAIL_REGEX.test(user) || MOBILE_REGEX.test(user)) {
      isValid = true;
    } else if (USERNAME_REGEX.test(user) && user?.length > 3 && !isUserName) {
      isValid = true;
    }
    return isValid;
  };

  handleUseCode = () => {
    this.handleGetOtp();
    this.setState({
      useCode: true,
      currentScreen: "LOGIN_OTP",
      showError: false,
    });
  };

  handleChange = (event) => {
    let updatedOTP = {
      ...this.state.OTP,
    };
    updatedOTP = event;
    this.setState({
      OTP: updatedOTP,
      showError: false,
      errMsg: "",
    });
    if (updatedOTP.length == 6) {
      let payload = {
        userName: this.state.login.userName,
        otp: updatedOTP,
      };
      if (this.state.currentScreen === "MFA_OTP") {
        this.setState((state) => ({ ...state, loading: true }));
        verifyMFAOtp({
          authenticatorType: this.state.authenticatorType,
          mfaToken: this.state.mfaToken,
          secret: updatedOTP,
        })
          .then((data) => {
            this.setState((state) => ({ ...state, loading: false }));
            const redirectURI = data["redirectURI"];
            window.location.href = redirectURI;
          })
          .catch((err) =>
            this.setState((state) => ({
              ...state,
              showError: true,
              errMsg: err,
              loading: false,
            }))
          );
      } else {
        this.props.onPostLoginData(payload);
      }
    }
  };

  isLoading = () => {
    let loading = false;
    if (
      this.state.loading ||
      this.props.postLoginDataState === "LOADING" ||
      this.props.postRequestOTPState === "LOADING"
    ) {
      loading = true;
    }
    return loading;
  };

  handleLoginDisable = () => {
    let isDisabled = true;
    if (!isEmpty(this.state.login.userName) && this.checkValidUserName()) {
      if (this.state.useCode) {
        if (this.state.OTP.length === 4) {
          isDisabled = false;
        }
      } else {
        if (this.state.isMFAChecked && isEmpty(this.state.login.password)) {
          isDisabled = true;
        } else {
          isDisabled = false;
        }

        // return false;
      }
    } else if (this.isLoading()) {
      isDisabled = true;
    }
    return isDisabled;
  };

  startTimer() {
    if (this.timer === 0 && this.state.seconds >= 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown = () => {
    let secondsCurrent = this.state.seconds - 1;
    this.setState({
      seconds: secondsCurrent,
    });
    if (secondsCurrent === 0) {
      clearInterval(this.timer);
    }
  };

  handleGetOtp = () => {
    let userType = "";
    if (MOBILE_REGEX.test(this.state.login.userName)) {
      userType = "SMS";
    } else if (EMAIL_REGEX.test(this.state.login.userName)) {
      userType = "EMAIL";
    }
    let data = { userName: this.state.login.userName, channel: userType };
    this.props.onPostRequestOTP(data);
    this.timer = 0;
  };

  handleEditUserName = () => {
    this.setState({
      useCode: false,
      OTP: "",
      showError: false,
      isMFAChecked: false,
      mfaEnabled: false,
      mfaToken: null,
      authenticator: [],
      currentScreen: "LOGIN",
    });
    clearInterval(this.timer);
  };

  getMfaOtp = (authenticatorType, retry = false) => {
    this.setState((state) => ({ ...state, loading: true }));
    challengeMFA({ authenticatorType, mfaToken: this.state.mfaToken, retry })
      .then((data) => {
        this.setState({
          ...this.state,
          currentScreen: "MFA_OTP",
          seconds: data?.retryInterval,
          loading: false,
        });
        this.startTimer();
      })
      .catch((err) =>
        this.setState((state) => ({
          ...state,
          showError: true,
          errMsg: err,
          loading: false,
        }))
      );
    this.timer = 0;
  };

  handleMFAClick = (authenticatorType) => {
    const authType = this.state.authenticator?.find(
      (obj) => obj.channel === authenticatorType
    )?.type;
    this.setState((state) => ({
      ...state,
      authenticatorType: authType,
      errMsg: "",
      showError: false,
    }));
    this.getMfaOtp(authType, false);
  };

  onClickContactSupport = () => {
    this.setState({ ...this.state, showContactSupport: true });
  };

  getButtonLabels = () => {
    let label = "Next";
    if (this.state.isMFAChecked && this.state.mfaEnabled) {
      label = "Authenticate";
    } else if (this.state.isMFAChecked && !this.state.mfaEnabled) {
      label = "Login";
    }
    return label;
  };

  renderLoginScreen() {
    return (
      <div className={styles.alignMiddle}>
        <div className={styles.alignDots}>
          <img src={dots} alt="betterplace" height="66px" />
        </div>
        <div
          style={{ marginBottom: "2px", marginTop: "50px", marginLeft: "20px" }}
        >
          <img src={BPlogo} alt="bplogo" height="40px" />
        </div>
        <div className={styles.loginHeadBold}> betterplace</div>
        <div>
          <div className={styles.loginHeading}>Login to your account</div>
          {!this.state.isMFAChecked ? (
            <div className={styles.inputs}>
              <div className={styles.label}>{process.env.REACT_APP_LOGIN_ACCOUNT_LABEL || `Email id / Phone number`}</div>
              <input
                name="userName"
                className={cx(
                  styles.inputField,
                  this.state.showError ? styles.error : null
                )}
                type="text"
                placeholder={`Enter ${process.env.REACT_APP_LOGIN_ACCOUNT_LABEL || 'email / mobile'}`}
                onChange={(event) => this.handleInputChange(event, "userName")}
                value={this.state.login.userName}
              />
            </div>
          ) : (
            <div>
              <div className={styles.textPrimary}>
                {this.state.login.userName}
                <img
                  src={editIcon}
                  alt="edit"
                  height="16px"
                  onClick={() => this.handleEditUserName()}
                  className={cx(styles.pointer, "ml-2")}
                />
              </div>
            </div>
          )}
          {this.state.login.userName &&
            this.state.isMFAChecked &&
            this.checkValidUserName() && (
              <div className={cx(styles.inputs)} style={{ marginTop: "20px" }}>
                <input hidden value={this.state.login.userName} />
                <div className={styles.label}>Password</div>
                <PasswordInput
                  placeholder="enter password"
                  value={this.state.login.password}
                  onChange={(event) =>
                    this.handleInputChange(event, "password")
                  }
                  className={styles.mt8}
                  error={this.state.showError}
                />

                <div className={cx("text-right", styles.mt8)}>
                  {process.env.REACT_APP_PASSWORD_RESET_REDIRECT_URL 
                  ? <Link
                      to={{ pathname: process.env.REACT_APP_PASSWORD_RESET_REDIRECT_URL }}
                      target="_blank"
                      className={styles.NavLinkfont}
                    >
                      {process.env.REACT_APP_FORGOT_PASSWORD_LABEL ?? 'Forgot/Create password?'}
                    </Link> 
                  : <Link
                      to={this.handleForgotPasswordUrl()}
                      className={styles.NavLinkfont}
                    >
                      {process.env.REACT_APP_FORGOT_PASSWORD_LABEL ?? 'Forgot/Create password?'}
                    </Link> }
                </div>
                {/* Display External password Reset flow, show only if the URL exists in the environment variable */}
                {process.env.REACT_APP_RESET_DOMAIN_ID_PASSWORD_URL && (
                  <div className={cx("text-right", styles.mt8)}>
                    <Link
                      to={{ pathname: process.env.REACT_APP_RESET_DOMAIN_ID_PASSWORD_URL}}
                      target="_blank"
                      className={styles.NavLinkfont}
                    >
                      Reset Domain ID Password
                    </Link>
                  </div>
                )}
              </div>
            )}
          {process.env.REACT_APP_IDENTITY_SHOW_OTP === 'true' && this.checkValidUserName(true) &&
            this.state.isMFAChecked &&
            !this.state.mfaEnabled && (
              <div
                className={styles.linkTextMedium}
                onClick={() => this.handleUseCode()}
              >
                Login with OTP
              </div>
            )}
        </div>

        {this.state.showError && (
          <div className={cx(styles.warning)}>
            <img src={warning} alt="warning" className="mr-1" />
            {this.props.error ?? this.state.errMsg}
          </div>
        )}
        {this.state.mfaEnabled && (
          <div className={cx(styles.mfaNotification, styles.marginTop)}>
            <img src={speakerIcon} alt="speaker" className="mr-1" />
            <span>
              Two factor authentication has been enabled for your account.
            </span>
          </div>
        )}
        <button
          tabIndex="0"
          onClick={(event) => this.handleNext(event)}
          disabled={this.handleLoginDisable()}
          className={cx(
            !this.handleLoginDisable()
              ? styles.buttonNextEnable
              : styles.buttonNextDisable,
            styles.buttonNext,
            styles.marginTop,
            this.isLoading() ? styles.buttonNextAnimation : null
          )}
        >
          <span className={styles.nextText}>{this.getButtonLabels()}</span>
          <img src={arrowRight} alt="next" height="14px" />
        </button>

        <div className={styles.footer}>
          Need help in login?
          <span
            onClick={() => this.onClickContactSupport()}
            className={styles.NavLinkfont}
          >
            Contact Support
          </span>
        </div>
      </div>
    );
  }

  renderSwitch(screen) {
    switch (screen) {
      case "MFA_SELECTION":
        return (
          <TfaSelection
            isEmailDisabled={
              !this.state.authenticator.find(
                (data) => data.type === "OTP_EMAIL"
              )
            }
            isMobileDisabled={
              !this.state.authenticator.find((data) => data.type === "OTP_SMS")
            }
            onContactClick={this.onClickContactSupport}
            onNextPress={this.handleMFAClick}
            error={this.props.error ?? this.state.errMsg}
            loading={
              this.props.postLoginDataState === "LOADING" ||
              this.props.postRequestOTPState === "LOADING" ||
              this.state.loading
            }
          />
        );
      case "LOGIN_OTP":
        return (
          <OtpScreen
            authenticatorType={this.state.authenticatorType}
            screen={screen}
            otp={this.state.OTP}
            seconds={this.state.seconds}
            userName={this.state.login.userName}
            getOtp={this.handleGetOtp}
            handleChange={this.handleChange}
            handleEditUserName={this.handleEditUserName}
            error={this.props.error ?? this.state.errMsg}
            loading={
              this.props.postLoginDataState === "LOADING" ||
              this.props.postRequestOTPState === "LOADING" ||
              this.state.loading
            }
          />
        );
      case "MFA_OTP":
        return (
          <OtpScreen
            authenticatorType={this.state.authenticatorType}
            screen={screen}
            otp={this.state.OTP}
            seconds={this.state.seconds}
            userName={this.state.login.userName}
            getOtp={() => this.getMfaOtp(this.state.authenticatorType, true)}
            handleChange={this.handleChange}
            handleEditUserName={this.handleEditUserName}
            error={this.props.error ?? this.state.errMsg}
            loading={
              this.props.postLoginDataState === "LOADING" ||
              this.props.postRequestOTPState === "LOADING" ||
              this.state.loading
            }
          />
        );
      case "LOGIN":
        return this.renderLoginScreen();
      default:
        return this.renderLoginScreen();
    }
  }

  render() {
    return (
      <>
        <div className={styles.container}>
          <div className={cx(styles.scrollbar, styles.pageContainer)}>
            <form>{this.renderSwitch(this.state.currentScreen)}</form>
          </div>
        </div>
        <ContactUsModal
          show={this.state.showContactSupport}
          handleClose={() =>
            this.setState({ ...this.state, showContactSupport: false })
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postLoginDataState: state.login.postLoginDataState,
    postRequestOTPState: state.login.postRequestOTPState,
    error: state.login.error,
    loginData: state.login.loginData,
    waitingTime: state.login.waitingTime,
    responseData: state.reset.responseData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPostLoginData: (data) => dispatch(actions.postLoginData(data)),
    onPostRequestOTP: (data) => dispatch(actions.requestOtp(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);
