import React, { Component } from 'react';

import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as actions from "./Store/action";

import styles from "./ForgotPassword.module.scss";
import cx from "classnames";
import warning from "../../assets/icons/warning.svg"
import ForgotPasswordSuccess from "./ForgotPasswordSuccess";
import ContactUsModal from "../../components/molecules/ContactUsModal/ContactUsModal";
import BPlogo from "../../assets/icons/betterPlaceNewLogo.svg";
import dots from "../../assets/icons/dots.svg";

class ResetPassword extends Component {

    state = {
        formData: {
            email: ''
        },
        enableNext: false,
        showError: false,
        showContactSupport: false,
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.formData !== this.state.formData) {
            if (this.state.formData.email !== '') {
                this.setState({
                    enableNext: true
                })
            } else {
                this.setState({
                    enableNext: false
                })
            }
        }
        if (prevProps.postForgotPasswordState !== this.props.postForgotPasswordState) {
            if (this.props.postForgotPasswordState === "ERROR") {
                this.setState({

                    showError: true
                })
            }
        }

    }

    handleInputChange = (event, inputIdentifier) => {

        let updatedFormData = {
            ...this.state.formData,
        }
        updatedFormData[inputIdentifier] = event.target ? event.target.value : event.value;
        this.setState({
            formData: updatedFormData,
        });
    }

    handleRedirect = () => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('defaultURI')) {
            window.location = params.get('defaultURI');
        } else {
            window.location = process.env.REACT_APP_IDENTITY_URL;
        }
    }

    handleNext = (event) => {
        event.preventDefault();
        let data = {
            "userName": this.state.formData.email
        }
        this.props.onPostForgotPasswordData(data);
    }

    onClickContactSupport = () => {
        this.setState({ ...this.state, showContactSupport: true });
      };

    render() {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const disableSubmitButton = !re.test(this.state.formData.email);
        return (
            <React.Fragment>
                {

                    this.props.postForgotPasswordState === "SUCCESS" ? <ForgotPasswordSuccess onContactClick={this.onClickContactSupport}/> :
                    <div className={styles.container}>
                    <div className={cx(styles.scrollbar, styles.pageContainer)}>
                        <div className={styles.alignMiddle}>
                            <div className={styles.alignDots}><img src={dots} alt="betterplace" height="66px" /></div>
                            <div style={{ marginBottom: '2px', marginTop: '50px', marginLeft: '20px' }}>
                                <img src={BPlogo} alt="bplogo" height="40px" />
                            </div>
                            <div className={styles.loginHeadBold}> betterplace</div>
                                <div className={styles.Heading}>Forgot your password?</div>
                                {this.state.showError ?
                                    <div className={styles.warning}>
                                        <img src={warning} alt='warning' /> email does not exist
                                    </div> : null}
                                <form>
                                    <div className={cx(styles.inputs, "mt-3")}>
                                        <span className={styles.label}>please enter your email id</span><br />
                                        <input
                                            name="email"
                                            className={cx(styles.inputField, this.state.showError ? styles.error : null)}
                                            type="text"
                                            placeholder="enter email id"
                                            onChange={(event) => this.handleInputChange(event, 'email')}
                                            value={this.state.formData.email}
                                        />
                                        <div className={styles.supportCol}>
                                            Need help in login?
                                            <span
                                            onClick={() => this.onClickContactSupport()}
                                            className={styles.SupportNavLinkfont}
                                            >
                                            Contact Support
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles.AlignButton}>
                                        <button
                                            onClick={(event) => this.handleNext(event)}
                                            disabled={!this.state.enableNext || disableSubmitButton}
                                            className={cx(!(!this.state.enableNext || disableSubmitButton) ? styles.buttonNextEnable : styles.buttonNextDisable,
                                                styles.buttonNext)}>
                                            <span className={styles.nextText}>Submit Email</span>
                                            {/* <img src={arrow} alt="arrow" className={styles.arrow} /> */}
                                        </button>
                                        <div onClick={() => this.handleRedirect()} className={styles.NavLinkfont}>cancel</div>
                                    </div>
                                </form>
                            </div>
                            </div>
                        </div>
                }
                <ContactUsModal
                    show={this.state.showContactSupport}
                    handleClose={() =>
                        this.setState({ ...this.state, showContactSupport: false })
                    }
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        postForgotPasswordState: state.forgot.postForgotPasswordState,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onPostForgotPasswordData: (data) => dispatch(actions.postForgotPasswordData(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));