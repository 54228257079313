export const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
export const MOBILE_REGEX = /^([+][9][1]|[9][1]|[0]){0,1}([ ]{0,1})([0-9]{1})([0-9]{9})$/;
export const USERNAME_REGEX = /^(?=.*[a-zA-Z])(?=[a-zA-Z0-9_])(?!.*[_\.\-]{2})(?!.*[_\.\-]$)[a-zA-Z0-9_\-\.]+$/;
//                              |------1-----|-------2-------|------3--------|------4------|--------5--------|
// 1. Make sure that there is at least one alphabet
// 2. Make sure that the username is starting with an alphabet, a number or an underscore
// 3. Make sure that underscore, dot or hyphen doesn't appear consecutively
// 4. Make sure that the username is not ending with underscore, dot or hyphen
// 5. Allow alphabets, numbers, underscore, hyphen and dot
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/

export const getPwaType = () => {
  let displayMode = 'browser';
  const mqStandAlone = '(display-mode: standalone)';
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    displayMode = 'standalone';
  }
  return displayMode;
}

export const getUserAgentData = () => {
  return navigator.userAgent||navigator.vendor||window.opera;
}
