import * as actionTypes from './actionTypes';
import axios from 'axios';

import { getUserAgentData, getPwaType } from '../../../utils'

const IDENTITY = process.env.REACT_APP_IDENTITY_API_URL;

//post login data
export const postLoginData = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.POST_LOGIN_DATA_LOADING
        })
        let apiUrl = IDENTITY + '/login';
        axios.post(apiUrl,data)
        
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    if(!window.ReactNativeWebView) {
                        if (window.clevertap) {
                          window.clevertap.event.push('Platform_Login', { deviceType: getUserAgentData(), platformType: getPwaType(), userName: data.userName, timestamp: Date() });
                        }
                    }
                    dispatch({
                        type: actionTypes.POST_LOGIN_DATA_SUCCESS,
                        loginData: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg  = error;
                // Temporary fix to gracefully handle redirection to blank page in case of error 403
                // Should be removed after error message is fixed from back end
                if(error.response && typeof error.response.data === 'string') {
                    error.response.data = {};
                    error.response.data.errorMessage = 'Something went wrong, please try again.';
                }
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage
                }
                dispatch({
                    type: actionTypes.POST_LOGIN_DATA_ERROR,
                    error: errMsg,
                });
            });
    };
}

//post login data
export const requestOtp = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.POST_REQUEST_OTP_LOADING
        })
        let apiUrl = IDENTITY + '/login/send-otp';
        axios.post(apiUrl,data)
        
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    if(!window.ReactNativeWebView){
                        let requestOTPEvent = '';
                        if(data.channel === 'SMS') {
                            requestOTPEvent = 'Platform_Login_Request_MobileOTP';
                        } else {
                            requestOTPEvent = 'Platform_Login_Request_EmailOTP';
                        };
                        if (window.clevertap) {
                          window.clevertap.event.push(requestOTPEvent, { deviceType: getUserAgentData(), platformType: getPwaType(), timestamp: Date(), userName: data.userName });
                        }
                    }
                    
                    dispatch({
                        type: actionTypes.POST_REQUEST_OTP_SUCCESS,
                        waitingTime: response.data
                    });
                }
            })
            .catch(error => {
                let errMsg  = error;
                if(error.response && error.response.data.errorMessage){
                    errMsg = error.response.data.errorMessage
                }
                dispatch({
                    type: actionTypes.POST_REQUEST_OTP_ERROR,
                    error: errMsg,
                });
            });
    };
}