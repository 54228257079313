import React from "react";
import styles from "./ResetPassword.module.scss";
import cx from "classnames";

import dots from "../../assets/icons/dots.svg";
import CheckIcon from "../../assets/icons/check.svg";

const metaData = {
  heading: "Password reset",
  titlePartOne: "Your password has been reset",
  titlePartTwo: "successfully",
  nextBtnTitle: "Proceed to Login",
};

const ResetPasswordSuccess = ({ handleClick = () => {} }) => {
  return (
    <div className={styles.resetPasswordSuccessContainer}>
      <div
        className={cx(
          styles.resetSuccessScrollbar,
          styles.resetSuccessPageContainer
        )}
      >
        <div className={styles.alignMiddle}>
          <div className={styles.alignDots}>
            <img src={dots} alt="betterplace" height="66px" />
          </div>

          <div className={styles.contentContainer}>
            <div>
              <img src={CheckIcon} alt="checkIcon" height="32.67px" />
            </div>
            <div className={styles.resetHeadBold}>{metaData.heading}</div>
            <div className={styles.subHeading}>{metaData.titlePartOne}</div>
            <div className={styles.subHeading}>{metaData.titlePartTwo}</div>
          </div>

          <button
            tabIndex="0"
            className={cx(styles.buttonLogin, styles.loginButtonNext)}
            onClick={handleClick}
          >
            <span className={styles.loginText}>{metaData?.nextBtnTitle}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
