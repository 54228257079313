import React, { useMemo, useState } from "react";
import cx from "classnames";

import styles from "./TfaSelection.module.scss";
import dots from "../../../assets/icons/dots.svg";
import shield from "../../../assets/icons/shield.svg";
import email from "../../../assets/icons/email.svg";
import mobile from "../../../assets/icons/mobile.svg";
import arrowRight from "../../../assets/icons/arrow-right.svg";
import cancel from "../../../assets/icons/cancel.svg";

const tfaMetaData = {
  title: "Two-factor Authentication",
  subHeading:
    "Your admin has enabled Multi-factor authentication for enhanced security.",
  contentTitle: "You may choose your mode of authentication below :",
  emailDisabledText:
    "Your email Id is not available.\n Please select phone OTP option or contact support.",
  mobileDisabledText:
    "Your phone number is not available.\n Please select email OTP option or contact support.",
  content: [
    {
      type: "EMAIL",
      title: "Via Email",
      icon: email,
      disabled: false,
      details: `You will receive an OTP on your registered gmail account`,
    },
    {
      type: "MOBILE",
      title: "Via Phone (SMS)",
      disabled: false,
      icon: mobile,
      details: `You will receive an OTP on your registered phone number`,
    },
  ],
};

const TfaSelection = ({
  onNextPress = () => {},
  onContactClick = () => {},
  isEmailDisabled = false,
  isMobileDisabled = false,
  error = "",
  loading = false,
}) => {
  const [selectedType, setSelectedType] = useState("");
  const contentList = useMemo(() => {
    return tfaMetaData?.content?.map((data) => ({
      ...data,
      disabled: data?.type === "MOBILE" ? isMobileDisabled : isEmailDisabled,
    }));
  }, [isEmailDisabled, isMobileDisabled]);

  const typeError = useMemo(() => {
    if (isEmailDisabled) {
      return tfaMetaData.emailDisabledText;
    } else if (isMobileDisabled) {
      return tfaMetaData.mobileDisabledText;
    } else {
      return "";
    }
  }, [isEmailDisabled, isMobileDisabled]);

  return (
    <div className={styles.container}>
      <div className={cx(styles.scrollbar, styles.pageContainer)}>
        <div className={styles.alignMiddle}>
          <div className={styles.alignDots}>
            <img src={dots} alt="betterplace" height="66px" />
          </div>
          <div
            style={{
              marginBottom: "20px",
              marginTop: "50px",
              marginLeft: "20px",
            }}
          >
            <img src={shield} alt="bplogo" height="70px" />
          </div>
          <div className={styles.Heading}>{tfaMetaData.title}</div>
          <div className={styles.SubHeading}>{tfaMetaData.subHeading}</div>
          <div className={styles.contentTitle}>{tfaMetaData.contentTitle}</div>
          <div className={styles.contentContainer}>
            {contentList?.map(({ title, tooltipText, details, type, icon, disabled }) => (
              <Content
                key={type}
                disabled={disabled}
                className={
                  type === selectedType ? styles.selectedContent : null
                }
                onPress={() => {
                  setSelectedType(type);
                }}
              >
                <div className={styles.iconCircle}>
                  <img
                    src={icon ?? email}
                    alt="email"
                    height={type === "MOBILE" ? "22px" : "14px"}
                  />
                </div>
                <div className={styles.itemTitle}>{title}</div>
                <div className={styles.itemDetails}>{details}</div>
              </Content>
            ))}
          </div>
          {(error || typeError) && (
            <div className={cx(styles.warning)}>
              <img src={cancel} alt="warning" height="16px" className="mr-1" />
              <span>{error || typeError}</span>
            </div>
          )}
          <button
            type="button"
            onClick={() => onNextPress(selectedType)}
            disabled={!selectedType || loading}
            className={cx(
              selectedType ? styles.buttonNextEnable : styles.buttonNextDisable,
              styles.buttonNext,
              styles.marginTop,
              loading ? styles.buttonNextAnimation : null
            )}
          >
            <span className={styles.nextText}>Continue</span>
            <img src={arrowRight} alt="next" height="14px" />
          </button>
          <div className={styles.footer}>
            Need help in login?
            <span onClick={onContactClick} className={styles.NavLinkfont}>
              Contact Support
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Content = ({ children, disabled, onPress, className, key }) => {
  return (
    <div
      key={key}
      className={
        disabled
          ? cx(styles.content, styles.disabledContent)
          : cx(styles.content, className)
      }
      onClick={!disabled && onPress}
    >
      {children}
    </div>
  );
};

export default TfaSelection;
